// Bootstrap JS and CSS

import './../css/main.scss';

// Bootstrap Popover
import "./vendor/popover";

// Bootstrap Tooltip
import "./vendor/tooltip";

// Font Awesome
import '@fortawesome/fontawesome-free/js/all.js';


// Generate HTML table for generated numbers including chosenForm
function generateGeneratedNumbersTable(chosenForm, numbers) {
    const table = document.createElement('table');
    table.classList.add('table', 'table-bordered', 'table-striped', 'generated-numbers-table');

    // Create table header
    const headerRow = document.createElement('thead');
    const headerRowContent = `
        <tr>
            <th scope="col">Form</th>
            ${numbers.map((_, index) => `<th scope="col">Number ${index + 1}</th>`).join('')}
        </tr>
    `;
    headerRow.innerHTML = headerRowContent;
    table.appendChild(headerRow);

    // Create table body
    const bodyRow = document.createElement('tbody');
    const bodyRowContent = `
        <tr>
            <td>${chosenForm}</td>
            ${numbers.map(num => `<td>${Math.round(num)}</td>`).join('')}
        </tr>
    `;
    bodyRow.innerHTML = bodyRowContent;
    table.appendChild(bodyRow);

    return table;
}

window.generateGeneratedNumbersTable = generateGeneratedNumbersTable;
